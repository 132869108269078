import {useStorage} from '@vueuse/core';
import type {AccountSummary} from '~/types/googleAnalytics';

export default function () {
  const accounts = useState<AccountSummary | undefined>('accounts', () => undefined);

  const selectedAccount = useStorage('account', {} as AccountSummary);

  const selectedProperty = useStorage('property', '');

  async function getAccountProperties() {
    const {data: authData} = useAuth();
    const {data} = await useFetch('https://analyticsadmin.googleapis.com/v1beta/accountSummaries', {
      headers: {
        'Authorization': `Bearer ${authData.value?.access_token}`,
        'Accept': 'application/json',
      },
    });

    accounts.value = data.value as AccountSummary;
  }

  return {
    selectedProperty,
    accounts,
    selectedAccount,
    getAccountProperties,
  };
}
